import {BillingAddOnTypeColumn} from "../../views/nomenclature/billing/billingaddontype/data/billing.add.on.type.column";
import BillingAddOnTypeDetail from "../../views/nomenclature/billing/billingaddontype/billing.add.on.type.detail";
import {BillingAddOnTypeEdit} from "../../views/nomenclature/billing/billingaddontype/data/billing.add.on.type.edit";

const BillingAddOnTypeRoute = [
    {resource: "billing_add_ons", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:BillingAddOnTypeColumn, actions: [], detailsComponent : BillingAddOnTypeDetail},
    {resource: "billing_add_ons", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: BillingAddOnTypeEdit},
    {resource: "billing_add_ons", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: BillingAddOnTypeEdit}
];

export default BillingAddOnTypeRoute;