
import {LeaveTypeColumn} from "../../views/nomenclature/leave/leavetype/data/leave.type.column";
import LeaveTypeDetail from "../../views/nomenclature/leave/leavetype/leave.type.detail";
import {LeaveTypeEdit} from "../../views/nomenclature/leave/leavetype/data/leave.type.edit";

const LeaveTypeRoute = [
    {resource: "leave_types", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:LeaveTypeColumn, actions: [], detailsComponent : LeaveTypeDetail},
    {resource: "leave_types", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: LeaveTypeEdit},
    {resource: "leave_types", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: LeaveTypeEdit}
];

export default LeaveTypeRoute;