import React from "react";

export const NotWorkingDayEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.not.working.day.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "abbreviation",
        label:"nomenclature.not.working.day.abbreviation",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.not.working.day.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "date",
        label:"nomenclature.not.working.day.date",
        type: "date",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];