import React from "react";

export const CurrencyEdit = [

    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.currency.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.currency.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "symbol",
        label:"nomenclature.currency.symbol",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "symbolNative",
        label:"nomenclature.currency.symbolnative",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "decimalDigits",
        label:"nomenclature.currency.decimaldigits",
        type: "number",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "rounding",
        label:"nomenclature.currency.rounding",
        type: "number",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "namePlural",
        label:"nomenclature.currency.nameplural",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];