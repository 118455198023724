import {ProfessionalStatusEdit} from "../../views/nomenclature/rh/professionalstatus/data/professional.status.edit";
import {ProfessionalStatusColumn} from "../../views/nomenclature/rh/professionalstatus/data/professional.status.column";
import ProfessionalStatusDetail from "../../views/nomenclature/rh/professionalstatus/professional.status.detail";

const ProfessionalStatusRoute = [
    {resource: "professional_statuss", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:ProfessionalStatusColumn, actions: [], detailsComponent : ProfessionalStatusDetail},
    {resource: "professional_statuss", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ProfessionalStatusEdit},
    {resource: "professional_statuss", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ProfessionalStatusEdit}
];

export default ProfessionalStatusRoute;