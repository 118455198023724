import React from "react";

export const TrialPeriodEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.trial.period.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.trial.period.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "quantity",
        label:"nomenclature.trial.period.quantity",
        type: "number",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "unit",
        label:"nomenclature.trial.period.quantity.unit",
        type: "select",
        resource: "units",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];