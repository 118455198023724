import React from "react";

export const ProfessionalStatusEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.professional.status.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.professional.status.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];