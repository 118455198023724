import {CivilityEdit} from "../../views/nomenclature/civility/civility/data/civility.edit";
import {CivilityColumn} from "../../views/nomenclature/civility/civility/data/civility.column";
import CivilityDetail from "../../views/nomenclature/civility/civility/civility.detail";

const CivilityRoute = [
    {resource: "civilitys", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:CivilityColumn, actions: [], detailsComponent : CivilityDetail},
    {resource: "civilitys", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CivilityEdit},
    {resource: "civilitys", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CivilityEdit}
];

export default CivilityRoute;