import React from "react";

export const LeaveTypeEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.leave.type.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "abbreviation",
        label:"nomenclature.leave.type.abbreviation",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.leave.type.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "externalReference",
        label:"nomenclature.leave.type.external.reference",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];