import {TimesheetTypeColumn} from "../../views/nomenclature/timesheet/timesheetType/data/timesheet.type.column";
import {TimesheetTypeEdit} from "../../views/nomenclature/timesheet/timesheetType/data/timesheet.type.edit";
import TimesheetTypeDetail from "../../views/nomenclature/timesheet/timesheetType/timesheet.type.detail";

const TimesheetTypeRoute = [
    {resource: "timesheet_types", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:TimesheetTypeColumn, actions: [], detailsComponent : TimesheetTypeDetail},
    {resource: "timesheet_types", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: TimesheetTypeEdit},
    {resource: "timesheet_types", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: TimesheetTypeEdit}
];

export default TimesheetTypeRoute;