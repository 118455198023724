import {LanguageEdit} from "../views/nomenclature/language/data/language.edit";
import {LanguageColumn} from "../views/nomenclature/language/data/language.column";
import LanguageDetail from "../views/nomenclature/language/language.detail";

const LanguageRoute = [
    {resource: "languages", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:LanguageColumn, actions: [], detailsComponent : LanguageDetail},
    {resource: "languages", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: LanguageEdit},
    {resource: "languages", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: LanguageEdit}
];

export default LanguageRoute;