import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getJWT = () => {

    console.log("debut cookie");
    console.log(cookies.get('jwtToken'));
    console.log("fin cookie");
    return cookies.get('jwtToken');

};

//TODO MARIN LIEN ORGA & NOMENCLATURE CAR SINON AUTRES PROJETS NE PEUVENT PAS RECUPERER
var getOrganization = function getOrganization() {
    var organization = cookies.get('organization');
    return organization !== undefined ? organization.id : "";
  };
  
  export const getConfigObject = () => {
    return {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'authorization': getJWT(),
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'organization': getOrganization()
      }
    };
  };


// export const getConfigObject = () => {
//     return {
//         headers: {
//             'authorization': getJWT(),
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//     };

// };