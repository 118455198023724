
import {CurrencyEdit} from "../views/nomenclature/currency/data/currency.edit";
import {CurrencyColumn} from "../views/nomenclature/currency/data/currency.column";
import CurrencyDetail from "../views/nomenclature/currency/currency.detail";


const CurrencyRoute = [
    {resource: "currencys", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:CurrencyColumn, actions: [], detailsComponent : CurrencyDetail},
    {resource: "currencys", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CurrencyEdit},
    {resource: "currencys", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CurrencyEdit}
];

export default CurrencyRoute;