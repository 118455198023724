import {WorkingTimeModalityEdit} from "../../views/nomenclature/rh/workingtimemodality/data/working.time.modality.edit";
import {WorkingTimeModalityColumn} from "../../views/nomenclature/rh/workingtimemodality/data/working.time.modality.column";
import WorkingTimeModalityDetail from "../../views/nomenclature/rh/workingtimemodality/working.time.modality.detail";

const WorkingTimeModalityRoute = [
    {resource: "working_time_modalitys", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:WorkingTimeModalityColumn, actions: [], detailsComponent : WorkingTimeModalityDetail},
    {resource: "working_time_modalitys", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: WorkingTimeModalityEdit},
    {resource: "working_time_modalitys", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: WorkingTimeModalityEdit}
];

export default WorkingTimeModalityRoute;