
import {TrialPeriodEdit} from "../../views/nomenclature/rh/trialperiod/data/trial.period.edit";
import {TrialPeriodColumn} from "../../views/nomenclature/rh/trialperiod/data/trial.period.column";
import TrialPeriodDetail from "../../views/nomenclature/rh/trialperiod/trial.period.detail";


const TrialPeriodRoute = [
    {resource: "trial_periods", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:TrialPeriodColumn, actions: [], detailsComponent : TrialPeriodDetail},
    {resource: "trial_periods", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: TrialPeriodEdit},
    {resource: "trial_periods", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: TrialPeriodEdit}
];

export default TrialPeriodRoute;