import {MaritalStatusEdit} from "../../views/nomenclature/civility/maritalstatus/data/marital.status.edit";
import {MaritalStatusColumn} from "../../views/nomenclature/civility/maritalstatus/data/marital.status.column";
import MaritalStatusDetail from "../../views/nomenclature/civility/maritalstatus/marital.status.detail";

const MaritalStatusRoute = [
    {resource: "marital_statuss", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:MaritalStatusColumn, actions: [], detailsComponent : MaritalStatusDetail},
    {resource: "marital_statuss", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: MaritalStatusEdit},
    {resource: "marital_statuss", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: MaritalStatusEdit}
];

export default MaritalStatusRoute;