
import {CompanyFunctionEdit} from "../../views/nomenclature/company/companyfunction/data/company.function.edit";
import {CompanyFunctionColumn} from "../../views/nomenclature/company/companyfunction/data/company.function.column";
import CompanyFunctionDetail from "../../views/nomenclature/company/companyfunction/company.function.detail";



const CompanyFunctionRoute = [

    {resource: "company_functions", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:CompanyFunctionColumn, actions: [], detailsComponent : CompanyFunctionDetail},
    {resource: "company_functions", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CompanyFunctionEdit},
    {resource: "company_functions", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CompanyFunctionEdit}

];

export default CompanyFunctionRoute;