
import {CompanyCategoryEdit} from "../../views/nomenclature/company/companycategory/data/company.category.edit";
import {CompanyCategoryColumn} from "../../views/nomenclature/company/companycategory/data/company.category.column";
import CompanyCategoryDetail from "../../views/nomenclature/company/companycategory/company.category.detail";



const CompanyCategoryRoute = [

    {resource: "company_categorys", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:CompanyCategoryColumn, actions: [], detailsComponent : CompanyCategoryDetail},
    {resource: "company_categorys", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CompanyCategoryEdit},
    {resource: "company_categorys", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CompanyCategoryEdit}

];

export default CompanyCategoryRoute;