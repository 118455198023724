import {ContractTypeEdit} from "../../views/nomenclature/rh/contracttype/data/contract.type.edit";
import {ContractTypeColumn} from "../../views/nomenclature/rh/contracttype/data/contract.type.column";
import ContractTypeDetail from "../../views/nomenclature/rh/contracttype/contract.type.detail";

const ContractTypeRoute = [
    {resource: "contract_types", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:ContractTypeColumn, actions: [], detailsComponent : ContractTypeDetail},
    {resource: "contract_types", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ContractTypeEdit},
    {resource: "contract_types", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ContractTypeEdit}
];

export default ContractTypeRoute;