import {WorkingTimeEdit} from "../../views/nomenclature/rh/workingtime/data/working.time.edit";
import {WorkingTimeColumn} from "../../views/nomenclature/rh/workingtime/data/working.time.column";
import WorkingTimeDetail from "../../views/nomenclature/rh/workingtime/working.time.detail";

const WorkingTimeRoute = [
    {resource: "working_times", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:WorkingTimeColumn, actions: [], detailsComponent : WorkingTimeDetail},
    {resource: "working_times", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: WorkingTimeEdit},
    {resource: "working_times", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: WorkingTimeEdit}
];

export default WorkingTimeRoute;