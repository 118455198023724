import React from "react";

export const CountryEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.country.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.country.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "nativeName",
        label:"nomenclature.country.nativename",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "indexPhone",
        label:"nomenclature.country.indexphone",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "continent",
        label:"nomenclature.country.continent",
        type: "select",
        resource: "continents",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "capital",
        label:"nomenclature.country.capital",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "currency",
        label:"nomenclature.country.currency",
        type: "select",
        resource: "currencys",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "languages",
        label:"nomenclature.country.languages",
        type: "select",
        resource: "languages",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "emoji",
        label:"nomenclature.country.emoji",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "emojiU",
        label:"nomenclature.country.emojiu",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];