import IntlMessages from "../../../../../helpers/intlMessages";
import React from "react";

export const BillingAddOnTypeColumn = [
    {
        name: <IntlMessages
            id="nomenclature.civility.datatable.column.code"
        />,
        selector: 'code',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages id="nomenclature.billing.add.on.datatable.column.name"/>,
        selector: 'name',
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages  id="nomenclature.common.status"/>,
        selector: 'enable',
        sortable: false,
        filtrable: true,

        cell : row =>  <div>
            {row.enable && <i className="fas fa-check" style={{fontSize: 15}}/>}
            {!row.enable && <i className="fas fa-ban" style={{fontSize: 15}}/>}
        </div>
    }
];