
import {IdentityDocumentColumn} from "../../views/nomenclature/rh/identitydocument/data/identity.document.column";
import {IdentityDocumentEdit} from "../../views/nomenclature/rh/identitydocument/data/identity.document.edit";
import IdentityDocumentDetail from "../../views/nomenclature/rh/identitydocument/identity.document.detail";


const IdentityDocumentRoute = [
    {resource: "identity_documents", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:IdentityDocumentColumn, actions: [], detailsComponent : IdentityDocumentDetail},
    {resource: "identity_documents", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: IdentityDocumentEdit},
    {resource: "identity_documents", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: IdentityDocumentEdit}
];

export default IdentityDocumentRoute;