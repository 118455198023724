import {CountryEdit} from "../views/nomenclature/country/data/country.edit";
import {CountryColumn} from "../views/nomenclature/country/data/country.column";
import CountryDetail from "../views/nomenclature/country/country.detail";


const CountryRoute = [
    {resource: "countrys", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:CountryColumn, actions: [], detailsComponent : CountryDetail},
    {resource: "countrys", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CountryEdit},
    {resource: "countrys", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CountryEdit}
];

export default CountryRoute;