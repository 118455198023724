
import {DayOffColumn} from "../../views/nomenclature/time/dayoff/data/day.off.column";
import {DayOffEdit} from "../../views/nomenclature/time/dayoff/data/day.off.edit";
import DayOffDetail from "../../views/nomenclature/time/dayoff/day.off.detail";

const DayOffRoute = [
    {resource: "day_offs", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:DayOffColumn, actions: [], detailsComponent : DayOffDetail},
    {resource: "day_offs", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: DayOffEdit},
    {resource: "day_offs", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: DayOffEdit}
];

export default DayOffRoute;