import {UnitEdit} from "../views/nomenclature/unit/data/unit.edit";
import {UnitColumn} from "../views/nomenclature/unit/data/unit.column";
import UnitDetail from "../views/nomenclature/unit/unit.detail";

const UnitRoute = [
    {resource: "units", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:UnitColumn, actions: [], detailsComponent : UnitDetail},
    {resource: "units", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: UnitEdit},
    {resource: "units", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: UnitEdit}
];

export default UnitRoute;