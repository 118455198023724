import React from "react";

export const PeriodTimeEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.period.time.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "abbreviation",
        label:"nomenclature.period.time.abbreviation",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.period.time.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "startTime",
        label:"nomenclature.period.time.start.time",
        type: "time",
        mask:"99:99",
        maskChar:"0",
        placeholder:"00:00",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "endTime",
        label:"nomenclature.period.time.end.time",
        type: "time",
        mask:"99:99",
        maskChar:"0",
        placeholder:"00:00",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "value",
        label:"nomenclature.period.time.value",
        type: "number",
        mask:"99,99",
        maskChar:"0",
        placeholder:"00,00",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "byDefault",
        label:"nomenclature.common.default",
        placeholder:"",
        type: "boolean",
        defaultValue: false,
        validation: {required: 'Ce champ est obligatoire.'}
    },
];