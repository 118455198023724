
import {ContinentEdit} from "../views/nomenclature/continent/data/continent.edit";
import {ContinentColumn} from "../views/nomenclature/continent/data/continent.column";
import ContinentDetail from "../views/nomenclature/continent/continent.detail";


const ContinentRoute = [

    {resource: "continents", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:ContinentColumn, actions: [], detailsComponent : ContinentDetail},
    {resource: "continents", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ContinentEdit},
    {resource: "continents", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ContinentEdit}

];

export default ContinentRoute;