
import {MutualColumn} from "../../views/nomenclature/rh/mutual/data/mutual.column";
import {MutualEdit} from "../../views/nomenclature/rh/mutual/data/mutual.edit";
import MutualDetail from "../../views/nomenclature/rh/mutual/mutual.detail";


const MutualRoute = [
    {resource: "mutuals", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:MutualColumn, actions: [], detailsComponent : MutualDetail},
    {resource: "mutuals", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: MutualEdit},
    {resource: "mutuals", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: MutualEdit}
];

export default MutualRoute;