import {PaymentTypeEdit} from "../views/nomenclature/paymenttype/data/payment.type.edit";
import {PaymentTypeColumn} from "../views/nomenclature/paymenttype/data/payment.type.column";
import PaymentTypeDetail from "../views/nomenclature/paymenttype/payment.type.detail";



const PaymentTypeRoute = [

    {resource: "payment_types", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:PaymentTypeColumn, actions: [], detailsComponent : PaymentTypeDetail},
    {resource: "payment_types", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: PaymentTypeEdit},
    {resource: "payment_types", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: PaymentTypeEdit}
];

export default PaymentTypeRoute;