
import {CompanyActivityAreaEdit} from "../../views/nomenclature/company/companyactivityarea/data/company.activity.area.edit";
import {CompanyActivityAreaColumn} from "../../views/nomenclature/company/companyactivityarea/data/company.activity.area.column";
import CompanyActivityAreaDetail from "../../views/nomenclature/company/companyactivityarea/company.activity.area.detail";

const CompanyActivityAreaRoute = [

    {resource: "company_activity_areas", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:CompanyActivityAreaColumn, actions: [], detailsComponent : CompanyActivityAreaDetail},
    {resource: "company_activity_areas", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CompanyActivityAreaEdit},
    {resource: "company_activity_areas", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: CompanyActivityAreaEdit}

];

export default CompanyActivityAreaRoute;