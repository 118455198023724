import {ReasonEndContractEdit} from "../../views/nomenclature/rh/reasonendcontract/data/reason.end.contract.edit";
import {ReasonEndContractColumn} from "../../views/nomenclature/rh/reasonendcontract/data/reason.end.contract.column";
import ReasonEndContractDetail from "../../views/nomenclature/rh/reasonendcontract/reason.end.contract.detail";

const ReasonEndContractRoute = [
    {resource: "reason_end_contracts", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:ReasonEndContractColumn, actions: [], detailsComponent : ReasonEndContractDetail},
    {resource: "reason_end_contracts", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ReasonEndContractEdit},
    {resource: "reason_end_contracts", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ReasonEndContractEdit}
];

export default ReasonEndContractRoute;