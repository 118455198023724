
import {PeriodTimeColumn} from "../../views/nomenclature/time/periodtime/data/period.time.column";
import PeriodTimeDetail from "../../views/nomenclature/time/periodtime/period.time.detail";
import {PeriodTimeEdit} from "../../views/nomenclature/time/periodtime/data/period.time.edit";

const PeriodTimeRoute = [
    {resource: "period_times", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:PeriodTimeColumn, actions: [], detailsComponent : PeriodTimeDetail},
    {resource: "period_times", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: PeriodTimeEdit},
    {resource: "period_times", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: PeriodTimeEdit}
];

export default PeriodTimeRoute;