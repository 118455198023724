import React from "react";

export const UnitEdit = [
    {
        name: "enable",
        label:"nomenclature.common.status",
        placeholder:"",
        type: "boolean",
        defaultValue: true,
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "code",
        label:"nomenclature.unit.code",
        placeholder:"",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "name",
        label:"nomenclature.unit.name",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "abbreviation",
        label:"nomenclature.unit.abbreviation",
        type: "text",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "category",
        label:"nomenclature.unit.category",
        type: "select",
        enum: "E_UC",
        validation: {required: 'Ce champ est obligatoire.'}
    },
    {
        name: "type",
        label:"nomenclature.unit.type",
        type: "select",
        enum: "E_UT",
        validation: {required: 'Ce champ est obligatoire.'}
    }
];