
import {ExperienceLevelColumn} from "../../views/nomenclature/rh/experiencelevel/data/experience.level.column";
import {ExperienceLevelEdit} from "../../views/nomenclature/rh/experiencelevel/data/experience.level.edit";
import ExperienceLevelDetail from "../../views/nomenclature/rh/experiencelevel/experience.level.detail";


const ExperienceLevelRoute = [
    {resource: "experience_levels", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:ExperienceLevelColumn, actions: [], detailsComponent : ExperienceLevelDetail},
    {resource: "experience_levels", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ExperienceLevelEdit},
    {resource: "experience_levels", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: ExperienceLevelEdit}
];

export default ExperienceLevelRoute;