
import {NotWorkingDayColumn} from "../../views/nomenclature/time/notworkingday/data/not.working.day.column";
import {NotWorkingDayEdit} from "../../views/nomenclature/time/notworkingday/data/not.working.day.edit";
import NotWorkingDayDetail from "../../views/nomenclature/time/notworkingday/not.working.day.detail";

const NotWorkingDayRoute = [
    {resource: "not_working_days", type: "index", host:`${process.env.REACT_APP_API_URL}/nomenclature-service`, columns:NotWorkingDayColumn, actions: [], detailsComponent : NotWorkingDayDetail},
    {resource: "not_working_days", type:"new", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: NotWorkingDayEdit},
    {resource: "not_working_days", type:"update", host :`${process.env.REACT_APP_API_URL}/nomenclature-service`, edit: NotWorkingDayEdit}
];

export default NotWorkingDayRoute;