import React from "react";
import continentRoute from "./continent.route";
import currencyRoute from "./currency.route";
import countryRoute from "./country.route";
import languageRoute from "./language.route";
import unitRoute from "./unit.route";
import paymentTypeRoute from "./payment.type.route";
import personFunctionRoute from "./company/company.function.route";
import personCategoryRoute from "./company/company.category.route";
import trialPeriodRoute from "./rh/trial.period.route";
import companyActivityAreaRoute from "./company/company.activity.area.route";
import civilityAreaRoute from "./civility/civility.route";
import contractTypeRoute from "./rh/contract.type.route";
import professionelStatusRoute from "./rh/professional.status.route";
import reasonEndContractRoute from "./rh/reason.end.contract.route";
import maritalStatusRoute from "./civility/marital.status.route";
import workingTimeModalityRoute from "./rh/working.time.modality.route";
import workingTimeRoute from "./rh/working.time.route";
import DayOffRoute from "./time/day.off.route";
import PeriodTimeRoute from "./time/period.time.route";
import LeaveTypeRoute from "./leave/leave.type.route";
import TimesheetTypeRoute from "./timesheet/timesheet.type.route";
import NotWorkingDayRoute from "./time/not.working.day.route";
import BillingAddOnTypeRoute from "./billing/billing.add.on.type.route";
import Maintenance from "../views/pages/error/maintenance";
import IdentityDocumentRoute from "./rh/identity.document.route";
import ExperienceLevelRoute from "./rh/experience.level.route";
import MutualRoute from "./rh/mutual.route";

let NomenclatureRoute = [];

if (Array.isArray(continentRoute)) {
    NomenclatureRoute.push(...continentRoute);
}
if (Array.isArray(currencyRoute)) {
    NomenclatureRoute.push(...currencyRoute);
}
if (Array.isArray(countryRoute)) {
    NomenclatureRoute.push(...countryRoute);
}
if (Array.isArray(languageRoute)) {
    NomenclatureRoute.push(...languageRoute);
}
if (Array.isArray(unitRoute)) {
    NomenclatureRoute.push(...unitRoute);
}
if (Array.isArray(paymentTypeRoute)) {
    NomenclatureRoute.push(...paymentTypeRoute);
}
if (Array.isArray(personFunctionRoute)) {
    NomenclatureRoute.push(...personFunctionRoute);
}
if (Array.isArray(personCategoryRoute)) {
    NomenclatureRoute.push(...personCategoryRoute);
}
if (Array.isArray(trialPeriodRoute)) {
    NomenclatureRoute.push(...trialPeriodRoute);
}
if (Array.isArray(companyActivityAreaRoute)) {
    NomenclatureRoute.push(...companyActivityAreaRoute);
}
if (Array.isArray(civilityAreaRoute)) {
    NomenclatureRoute.push(...civilityAreaRoute);
}
if (Array.isArray(contractTypeRoute)) {
    NomenclatureRoute.push(...contractTypeRoute);
}
if (Array.isArray(professionelStatusRoute)) {
    NomenclatureRoute.push(...professionelStatusRoute);
}
if (Array.isArray(reasonEndContractRoute)) {
    NomenclatureRoute.push(...reasonEndContractRoute);
}
if (Array.isArray(maritalStatusRoute)) {
    NomenclatureRoute.push(...maritalStatusRoute);
}
if (Array.isArray(workingTimeModalityRoute)) {
    NomenclatureRoute.push(...workingTimeModalityRoute);
}
if (Array.isArray(workingTimeRoute)) {
    NomenclatureRoute.push(...workingTimeRoute);
}
if (Array.isArray(DayOffRoute)) {
    NomenclatureRoute.push(...DayOffRoute);
}
if (Array.isArray(NotWorkingDayRoute)) {
    NomenclatureRoute.push(...NotWorkingDayRoute);
}
if (Array.isArray(PeriodTimeRoute)) {
    NomenclatureRoute.push(...PeriodTimeRoute);
}
if (Array.isArray(LeaveTypeRoute)) {
    NomenclatureRoute.push(...LeaveTypeRoute);
}
if (Array.isArray(TimesheetTypeRoute)) {
    NomenclatureRoute.push(...TimesheetTypeRoute);
}
if (Array.isArray(BillingAddOnTypeRoute)) {
    NomenclatureRoute.push(...BillingAddOnTypeRoute);
}
if (Array.isArray(IdentityDocumentRoute)) {
    NomenclatureRoute.push(...IdentityDocumentRoute);
}
if (Array.isArray(ExperienceLevelRoute)) {
    NomenclatureRoute.push(...ExperienceLevelRoute);
}
if (Array.isArray(MutualRoute)) {
    NomenclatureRoute.push(...MutualRoute);
}

NomenclatureRoute.push({ path: "/maintenance", component: Maintenance});

export default NomenclatureRoute;