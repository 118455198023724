import React, {useState} from "react";
import IntlMessages from "../../../helpers/intlMessages";
import AdaAlertDelete from "../../components/adaalert/ada.alert.delete";
import AdaAlertEnable from "../../components/adaalert/ada.alert.enable";
import AdaIndexResource from "./ada.index.resource";

import {deleteMethod} from "../services/crud.service";

export const Actions = (context, handlerOpenModalDelete, actions, selectedRows) => {
    let list = [
        {
            "typeAction": "UPDATE",
            "href": `/${context}/` + (selectedRows && selectedRows.length == 1 ? selectedRows[0].id:"") + `/edit`,
            "actionName": <IntlMessages id={`action.common.update`}/>,
            "patternEnable": "1"
        },
        {
            "typeAction": "DELETE",
            "actionHandlerInte": handlerOpenModalDelete,
            "actionName": <IntlMessages id={`iam.common.delete`}/>,
            "patternEnable": "1,*"
        }, ...actions
    ]
    return list;
}


const AdaIndexCrud = (props) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);

    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };
    const action = Actions(props.context, handlerOpenModalDelete, props.actions, selectedRows);

    const callbackDelete = () => {
        setModalShow(false);
        setRefresh(true);
    }

    return (<div>
            <AdaAlertDelete modalShow={modalShow}
                            handlerYes={() => deleteMethod(props.host, props.context, callbackDelete, selectedRows)}
                            handlerNo={() => {
                                setModalShow(false)
                            }}/>
            <AdaIndexResource host={props.host} context={props.context} setSelectedRows={setSelectedRows}
                              refresh={refresh}
                              columns={props.columns} actions={action}>
            </AdaIndexResource>
            {/*{selectedRows && selectedRows.length == 1 &&*/}
            {/*    <props.detailComponent hideDetails={false}   selectedElement = {selectedRows[0]}/>*/}
            {/*}*/}
        </div>
    );
};
export default (AdaIndexCrud);